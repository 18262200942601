import React from "react";
import interactive1 from "../assets/images/interactive1.svg";
import interactive2 from "../assets/images/interactive2.svg";
import interactive3 from "../assets/images/interactive3.svg";
import interactive4 from "../assets/images/interactive4.svg";

import "../assets/css/interactiveSecurity.css";

export const InteractiveSecurity = () => {
  return (
    <div className="security">
      <h2>
        {" "}
        <span>BIENVENIDO A </span> <br />
        LA SEGURIDAD INTERACTIVA
      </h2>
      <p>
        Con la nueva plataforma <b>ADT smart security</b> puedes acceder a
        información en tiempo real, video analítica, automatización y
        geo-servicios
      </p>
      <div className="security-images">
        <img src={interactive1} alt="Security icon" />
        <img src={interactive2} alt="Security icon" />
        <img src={interactive3} alt="Security icon" />
        <img src={interactive4} alt="Security icon" />
      </div>
    </div>
  );  
};
