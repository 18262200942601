import React from "react";
import "../assets/css/floating.css";
import phoneIcon from "../assets/images/phoneIcon.svg";

export const Floating = (data) => {
  const phone = data.data.phoneNumber
  const numberIcon = data.data.phoneNumberIcon
  const url = window.location.pathname;
  return (
    <div className="floating-button" type="button">
      <img src={phoneIcon} alt="Phone" className="phoneIcon" />
      {url === "/seguridad" ? (
        <a href="tel:6000061055">
          <span>Obtener promo </span> <b>600-006-1055</b>
        </a>
      ):(
        <a href={`tel:${phone}`}>
          <span>Obtener promo </span> <b>{numberIcon}</b>
        </a>
      )}
      
    </div>
  );
};
