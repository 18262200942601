import Carousel from "react-bootstrap/Carousel";

import kitAlarmas1 from "../assets/images/kitAlarmas1.png";
import kitAlarmas2 from "../assets/images/kitAlarmas2.png";
import kitAlarmas1Mobile from "../assets/images/kitAlarmas1Mobile.png";
import kitAlarmas2Mobile from "../assets/images/kitAlarmas2Mobile.png";

import "../assets/css/slider.css";

export const SliderProducts = (data) => {
  const phone = data.data.phoneNumber
  return (
    <div className="slider-title">
      <h2 className="slider-products-title">ALARMAS INTELIGENTES</h2>

      <div className="carousel">
        <Carousel>
          <Carousel.Item>
            {window.screen.width < 1001 ? (
              <a href={`tel:${phone}`}>
                <img
                  src={kitAlarmas1Mobile}
                  alt="kitAlarmas"
                  style={{ width: "100%" }}
                />
              </a>
            ) : (
              <a href={`tel:${phone}`}>
                <img
                  src={kitAlarmas1}
                  alt="kitAlarmas"
                  className="d-block w-100"
                />
              </a>
            )}
          </Carousel.Item>
          <Carousel.Item>
            {window.screen.width < 1001 ? (
              <a href={`tel:${phone}`}>
                <img
                  src={kitAlarmas2Mobile}
                  alt="kitAlarmas"
                  style={{ width: "100%" }}
                />
              </a>
            ) : (
              <a href={`tel:${phone}`}>
                <img
                  src={kitAlarmas2}
                  alt="kitAlarmas"
                  className="d-block w-100"
                />
              </a>
            )}
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};
