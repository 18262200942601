import React from "react";
import Carousel from "react-bootstrap/Carousel";

import promocionAlarma1 from "../assets/images/promocionAlarma1.png";
import promocionAlarma2 from "../assets/images/promocionAlarma2.png";
import promocionAlarma3 from "../assets/images/promocionAlarma3.png";
import promocionAlarma1Mobile from "../assets/images/promocionAlarma1Mobile.png";
import promocionAlarma2Mobile from "../assets/images/promocionAlarma2Mobile.png";
import promocionAlarma3Mobile from "../assets/images/promocionAlarma3Mobile.png";

import "../assets/css/slider.css";

export const Slider = (data) => {
  const url = window.location.pathname;
  const phone = data.data.phoneNumber

  return (
    <div className="slider-title">
      <h2 className="slider-promos-title">ALARMAS INTELIGENTES</h2>

      <div className="carousel">
        {url === "/seguridad" ? (
          <Carousel>
            <Carousel.Item>
              {window.screen.width < 1001 ? (
                <a href="tel:6000061055">
                  <img
                    src={promocionAlarma1Mobile}
                    alt="promo"
                    style={{ width: "100%" }}
                  />
                </a>
              ) : (
                <a href="tel:6000061055">
                  <img
                    src={promocionAlarma1}
                    alt="promo"
                    className="d-block w-100"
                  />
                </a>
              )}
            </Carousel.Item>
            <Carousel.Item>
              {window.screen.width < 1001 ? (
                <a href="tel:6000061055">
                  <img
                    src={promocionAlarma2Mobile}
                    alt="promo"
                    style={{ width: "100%" }}
                  />
                </a>
              ) : (
                <a href="tel:6000061055">
                  <img
                    src={promocionAlarma2}
                    alt="promo"
                    className="d-block w-100"
                  />
                </a>
              )}
            </Carousel.Item>
            <Carousel.Item>
              {window.screen.width < 1001 ? (
                <a href="tel:6000061055">
                  <img
                    src={promocionAlarma3Mobile}
                    alt="promo"
                    style={{ width: "100%" }}
                  />
                </a>
              ) : (
                <a href="tel:6000061055">
                  <img
                    src={promocionAlarma3}
                    alt="promo"
                    className="d-block w-100"
                  />
                </a>
              )}
            </Carousel.Item>
          </Carousel>
        ):(
          
        <Carousel>
          <Carousel.Item>
            {window.screen.width < 1001 ? (
              <a href={`tel:${phone}`}>
                <img
                  src={promocionAlarma1Mobile}
                  alt="promo"
                  style={{ width: "100%" }}
                />
              </a>
            ) : (
              <a href={`tel:${phone}`}>
                <img
                  src={promocionAlarma1}
                  alt="promo"
                  className="d-block w-100"
                />
              </a>
            )}
          </Carousel.Item>
          <Carousel.Item>
            {window.screen.width < 1001 ? (
              <a href={`tel:${phone}`}>
                <img
                  src={promocionAlarma2Mobile}
                  alt="promo"
                  style={{ width: "100%" }}
                />
              </a>
            ) : (
              <a href={`tel:${phone}`}>
                <img
                  src={promocionAlarma2}
                  alt="promo"
                  className="d-block w-100"
                />
              </a>
            )}
          </Carousel.Item>
          <Carousel.Item>
            {window.screen.width < 1001 ? (
              <a href={`tel:${phone}`}>
                <img
                  src={promocionAlarma3Mobile}
                  alt="promo"
                  style={{ width: "100%" }}
                />
              </a>
            ) : (
              <a href={`tel:${phone}`}>
                <img
                  src={promocionAlarma3}
                  alt="promo"
                  className="d-block w-100"
                />
              </a>
            )}
          </Carousel.Item>
        </Carousel>
        )}
      </div>
    </div>
  );
};
