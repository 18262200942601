export const chileRegions = [
  {
    id: "1",
    region: "Arica y Parinacota",
  },
  {
    id: "2",
    region: "Tarapacá",
  },
  {
    id: "3",
    region: "Antofagasta",
  },
  {
    id: "4",
    region: "Atacama",
  },
  {
    id: "5",
    region: "Coquimbo",
  },
  {
    id: "6",
    region: "Valparaíso",
  },
  {
    id: "7",
    region: "Región Metropolitana de Santiago ",
  },
  {
    id: "8",
    region: "Libertador General Bernardo O’Higgins",
  },
  {
    id: "9",
    region: "Maule",
  },
  {
    id: "10",
    region: "Ñuble",
  },
  {
    id: "11",
    region: "Biobío",
  },
  {
    id: "12",
    region: "La Araucanía",
  },
  {
    id: "13",
    region: "Los Ríos",
  },
  {
    id: "14",
    region: "Los Lagos",
  },
  {
    id: "15",
    region: "Aysén del General Carlos Ibáñez del Campo",
  },
  {
    id: "16",
    region: "Magallanes y la Antártica Chilena",
  },
];
