import React from "react";
import location from "../assets/images/locationIcon.svg";
import mail from "../assets/images/mailIcon.svg";
import phoneIcon from "../assets/images/phoneIcon.svg";
import facebook from "../assets/images/facebookIcon.svg";
import "../assets/css/footer.css";

export const Footer = (data) => {
  const phone = data.data.phoneNumber
  const numberIcon = data.data.phoneNumberIcon
  const url = window.location.pathname;
  return (
    <div className="footer">
      <h2>CONTACTO</h2>
      <ul>
        <li>
          {" "}
          <img src={location} alt="Location" /> Santiago de Chile{" "}
        </li>
        <li>
          {" "}
          <a target="_blank" rel="noreferrer" href="mailto:info@asesoradt.cl">
            <img src={mail} alt="Mail" /> info@asesoradt.cl
          </a>
        </li>
        <li>
          {" "}
          <>
          {url === "/seguridad" ? (
            <a target="_blank" rel="noreferrer" href="tel:6000061055">
              <img src={phone} alt="Phone" /> 600 006 1055
            </a>
          ):(
            <a target="_blank" rel="noreferrer" href={`tel:${phone}`}>
              <img src={phoneIcon} alt="Phone" /> {numberIcon}
            </a>
          )}
          </>
        </li>
        <li>
          {" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/Tu-alarma-ahora-Chile-114273526666007"
          >
            <img src={facebook} alt="Facebook" /> Tu alarma ahora chile
          </a>
        </li>
      </ul>
    </div>
  );
};
