import React, { useState, useEffect }  from 'react'
import { Floating } from "./components/Floating";
import { Logo } from "./components/Logo";
import { Slider } from "./components/Slider";
import { SliderProducts } from "./components/SliderProducts";
import { SliderServices } from "./components/SliderServices";
import { Form } from "./components/Form";
import { InteractiveSecurity } from "./components/InteractiveSecurity";
import { Services } from "./components/Services";
import { Instructions } from "./components/Instructions";
import { BestTechnology } from "./components/BestTechnology";
import { Footer } from "./components/Footer";

import "./assets/css/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import PoliticasPrivacidad from "./components/PoliticasPrivacidad";


function App() {
  const url = window.location.pathname;
  const [data, setData] = useState(null);

  const fetchData = async () =>{
    const data = await fetch('landing-config.json')
    return data.json()
  }
  
  useEffect(() => {
    fetchData()
      .then((response) => {
        setData(response); // Store the resolved value in state
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div>
      {url === "/politicasdeprivacidad" ? (
        <PoliticasPrivacidad/>
      ):(
        <>
          {data !== null ? (
            <div>
              <div className="fullPage">
                <div className="top-bar">
                  <Logo />
                  <Floating data={data}/>
                </div>
                <div className="slider-position">
                  {url === "/servicios" ? (
                    <SliderServices data={data}/>
                  ) : url === "/productos" ? (
                    <SliderProducts data={data}/>
                  ) : (
                    <Slider data={data}/>
                  )}
                </div>
                <div className="form-position">
                  <Form />
                </div>
                <div className="interactive-position">
                  <InteractiveSecurity />
                </div>
                <div className="divider-position"></div>
                <div className="technology-position">
                  <BestTechnology data={data}/>
                </div>
                <div className="services-position">
                  <Services />
                </div>
                <div className="instructions-position">
                  <Instructions />
                </div>
                <div className="footer-position">
                  <Footer data={data}/>
                </div>
              </div>
            </div>
        ):(<></>)}
        </>
      )}
    </div>
  );
}

export default App;
