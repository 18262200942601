import React from "react";

export const FormMessage = ({ correctValidation }) => {
  return (
    <div>
      {correctValidation === 1 ? (
        <div
          className="alert alert-success display text-center"
          style={{ fontSize: "1rem" }}
          role="alert"
        >
          Gracias!
          <br />
          <i className="glyphicon glyphicon-thumbs-up"></i> Uno de nuestros
          representantes lo contactará a la brevedad.
        </div>
      ) : correctValidation === 2 ? (
        <div
          className="alert alert-danger  display text-center"
          style={{ fontSize: "1rem" }}
          role="alert"
        >
          Numero de teléfono no válido! <br />
          <i className="glyphicon glyphicon-thumbs-up"></i>
          Por favor, verifique y vuelva a intentar.
        </div>
      ) : correctValidation === 3 ? (
        <div
          className="alert alert-danger  display text-center"
          style={{ fontSize: "1rem" }}
          role="alert"
        >
          Email no válido! <br />
          <i className="glyphicon glyphicon-thumbs-up"></i>
          Por favor, verifique y vuelva a intentar.
        </div>
      ) : correctValidation === 4 ? (
        <div
          className="alert alert-danger  display text-center"
          style={{ fontSize: "1rem" }}
          role="alert"
        >
          Región no válida! <br />
          <i className="glyphicon glyphicon-thumbs-up"></i>
          Por favor, seleccione una y vuelva a intentar.
        </div>
      ) : correctValidation === 5 ? (
        <div
          className="alert alert-danger  display text-center"
          style={{ fontSize: "1rem" }}
          role="alert"
        >
          A ocurrido un error inesperado <br />
          <i className="glyphicon glyphicon-thumbs-up"></i>
          Por favor, intente nuevamente mas tarde.
        </div>
      ) : null}
    </div>
  );
};
